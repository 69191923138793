<template>
  <section class="space-y-10">
    <offline-action-alert v-if="!isOnline" />
    <QuickInfo />
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <h3 class="subheader">
        Cash Bag Movement
      </h3>
      <br />
      <asom-card>  
        <asom-grid :sm="1" :md="2">
          <asom-form-field :label="'Location'" class="col-span-2">
            <p class="mt-3 text-sm text-gray-500">AFC</p>
          </asom-form-field>

          <div
            class="bg-white overflow-hidden sm:rounded-lg sm:shadow bg-gray-50"
          >
            <div class=" px-4 py-3 border-b border-gray-200">
              <div
                class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
              >
                <div class="ml-4 mt-2">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    In (Cash Bag S/N)
                  </h3>
                </div>
                <div class="ml-4 mt-2 flex-shrink-0">
                  <add-move-cash-bag
                    button-icon="math-plus"
                    @add-bag="addIncomingBag"
                  />
                </div>
              </div>
            </div>
            <ul class="divide-y divide-gray-200">
              <template v-for="bag in incomingCashBagList" :key="bag.tempId">
                <li>
                  <div class="px-4 py-3">
                    <div class="flex items-center justify-between">
                      <div class="text-sm font-medium text-gray-600 truncate">
                        S/N {{ bag.bagNo }}
                      </div>
                      <div class="ml-2 flex-shrink-0 flex">
                        <asom-button
                          variant="error"
                          size="sm"
                          outline
                          rounded="none"
                          icon="trash"
                          text=""
                          @click="removeBag(bag.tempId)"
                        >
                        </asom-button>
                      </div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>

          <div
            class="bg-white overflow-hidden sm:rounded-lg sm:shadow bg-gray-50"
          >
            <div class=" px-4 py-3 border-b border-gray-200">
              <div
                class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
              >
                <div class="ml-4 mt-2">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Out (Cash Bag S/N)
                  </h3>
                </div>
                <div class="ml-4 mt-2 flex-shrink-0">
                  <add-move-cash-bag
                    button-icon="math-minus"
                    @add-bag="addOutgoingBag"
                  />
                </div>
              </div>
            </div>
            <ul class="divide-y divide-gray-200">
              <template v-for="bag in outgoingCashBagList" :key="bag.tempId">
                <li>
                  <div class="px-4 py-3">
                    <div class="flex items-center justify-between">
                      <div class="text-sm font-medium text-gray-600 truncate">
                        S/N {{ bag.bagNo }}
                      </div>
                      <div class="ml-2 flex-shrink-0 flex">
                        <asom-button
                          variant="error"
                          size="sm"
                          outline
                          rounded="none"
                          icon="trash"
                          text=""
                          @click="removeBag(bag.tempId)"
                        >
                        </asom-button>
                      </div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
          <p
            v-if="inputStates('formData.cashBagList') === 'error'"
            class="text-sm text-red-700 col-span-2"
          >
            {{ "List of cash bag must not be empty." }}
          </p>

          <asom-form-field
            v-if="incomingCashBagList.length > 0"
            :label="'Safebox Status'"
            class="col-span-2"
          >
            <asom-input-radio-group
              class="mt-3"
              horizontal
              v-model="formData.outsideSafe"
              :options="locationOptions"
            />
          </asom-form-field>

          <asom-form-field
            :label="'Search Witness'"
            v-if="requireWitness"
            :state="inputStates('formData.witness')"
            description="Witness required for moving cash bag into safe"
            error="Witness is required."
          >
            <asom-input-select
              :state="inputStates('formData.witness')"
              v-model="formData.witness"
              :loading="isLoadingOfficers"
              :options="officerOptions"
              @search-change="queryOfficerList"
            />
          </asom-form-field>
        </asom-grid>

        <template #footer>
          <div class="flex justify-between flex-1">
            <asom-button variant="link" text="Cancel" @click="$router.push({name: 'Cashbag Main Page'})" />
            <asom-button text="Confirm" @click="onConfirmBtnClick" />
          </div>
        </template>
      </asom-card>
    </article>

    <asom-modal title="Confirm" v-model="showModal" :dismissible="false">
      <div v-if="hasBagNotMovedFromPSC" class="pt-2">
        <p class="pb-2">There are Bags which not moved out of PSC</p>
        <asom-input-checkbox
          v-model="isForced"
          label="Force Move In"
          description="Force Bags to Move in to AFC"
        />
      </div>
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="showModal = false; isForced = false;"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </section>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { moveCashBag, listBagStatus } from "../../../../services/cashManagement.service";
import { listUsers } from "../../../../services/user.service";
import QuickInfo from "../../_QuickInfo.vue";
import AddMoveCashBag from "./AddMoveCashBag.vue";
import OfflineActionAlert from "@/layout/OfflineActionAlert"

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  components: {
    QuickInfo,
    AddMoveCashBag,
    OfflineActionAlert,
  },
  data() {
    return {
      showModal: false,
      isForced: false,
      isSubmitting: false,
      isLoadingOfficers: false,
      error: null,
      formData: {
        outsideSafe: true,
        witness: null,
        cashBagList: [],
      },
      incomingBagStatusList: [],
    };
  },
  validations() {
    let rules = {
      formData: {
        cashBagList: {
          required,
          minLength: minLength(1),
          $each: {
            bagNo: {
              required,
            },
          },
        },
      },
    };

    if (this.requireWitness) {
      rules.formData.witness = {
        required,
      };
    }

    return rules;
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
      pscId: "cashManagement/stationCashAccounts/pscId",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
      stationId: "selectedStation/id",
      lineId: "selectedStation/lineId",
      isOnline: "apiStatus/isCMModuleOnline",
    }),
    locationOptions() {
      return [
        { value: false, label: "In Safe" },
        { value: true, label: "Outside of Safe" },
      ];
    },
    incomingCashBagList() {
      return this.formData.cashBagList.filter(({ movingIn }) => movingIn);
    },
    outgoingCashBagList() {
      return this.formData.cashBagList.filter(({ movingIn }) => !movingIn);
    },
    requireWitness() {
      return !this.formData.outsideSafe && this.incomingCashBagList.length > 0;
    },
    hasBagNotMovedFromPSC() {
      return this.incomingBagStatusList.filter(b => b.isAtPSC && !b.isMovedOut).length > 0
    },
    submissionData() {
      return {
        AFCId: this.afcId,
        PSCId: this.pscId,
        outsideSafe: this.formData.outsideSafe,
        witnessId: get(this.formData.witness, "value", null),
        witnessName: get(this.formData.witness, "label", null),
        cashBagList: [...this.formData.cashBagList],
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      }
    }
  },
  methods: {
    addIncomingBag(bagData) {
      this.formData.cashBagList = [
        ...this.formData.cashBagList,
        {
          ...bagData,
          movingIn: true,
        },
      ];
    },
    addOutgoingBag(bagData) {
      this.formData.cashBagList = [
        ...this.formData.cashBagList,
        {
          ...bagData,
          movingIn: false,
        },
      ];
    },
    removeBag(bagTempId) {
      this.formData.cashBagList = this.formData.cashBagList.filter(
        ({ tempId }) => tempId !== bagTempId
      );
    },
    onConfirmBtnClick() {
      this.error = null;
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.formData.$invalid) {
        this.error = "Please complete all required fields";
        this.$scrollTop();
        return;
      }
      this.showModal = true;
    },
    async queryOfficerList(query) {
      this.isLoadingOfficers = true;
      if (!query) {
        this.isLoadingOfficers = false;
        this.officerOptions = [];
      }
      const result = await listUsers({ search: query.trim() });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingOfficers = false;
        this.error = null;
        this.officerOptions = get(result, "payload.list").map(({ id, name }) => ({
          label: name,
          value: id,
        }));
      } else {
        this.isLoadingOfficers = false;
        this.error = result.payload;
        this.officerOptions = [];
      }
    },
    async getIncomingBagStatus() {
      this.incomingBagStatusList = [];
      if (this.incomingCashBagList.length === 0)
        return;
      this.error = null;
      this.isSubmitting = true;
      const bagNoList = this.incomingCashBagList.map(b => b.bagNo)
      const result = await listBagStatus({
        stationId: this.stationId,
        lineId: this.lineId,
        bagNoList: this.incomingCashBagList.map(b => b.bagNo)
      })
      if (result.success) {
        const list = get(result.payload, "bagStatusList", []);
        this.incomingBagStatusList = list.filter(({ bagNo }) => bagNoList.includes(bagNo));
      } else {
        this.error = result.payload;
      }
      this.isSubmitting = false;
    },
    onSubmit() {
      if (this.isOnline) {
        this.moveCashBagAsync();
      } else {
        this.$store.dispatch('offlineData/moveAFCCashBags', this.submissionData);
        this.onCompletion();
      }
    },
    onCompletion() {
      this.$router.push({ name: "Cashbag Main Page" });
    },
    async moveCashBagAsync() {
      if (!this.isForced) {
        await this.getIncomingBagStatus();
      }
      if (this.hasBagNotMovedFromPSC && !this.isForced) {
        return;
      }
      this.error = null;
      this.isSubmitting = true;
      const result = await moveCashBag(this.submissionData);
      this.isSubmitting = false;
      if (!result.success) {
        this.error = result.payload;
        this.showModal = false;
        this.isForced = false;
        this.$scrollTop();
      } else {
        this.onCompletion();
      }
    },
  },
};
</script>
